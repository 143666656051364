import React from "react";
import Sidebar from "../../Components/Sidebar";
import { WingBlank } from "antd-mobile";
import reimburseIcon from "../../assets/icons/reimburseBtn.svg";

export default function reimbursementDashboard(props) {
	return (
		<div>
			<div className="flex">
				<Sidebar />
				<div className="h-screen flex-1 p-7">
					<WingBlank size="md">
						<div className="flex justify-between md:flex-row flex-col gap-2 mt-8">
							<div>
								<div className=" text-dark-black font-bold text-lg mt-4">Check My Reimburse History</div>
								<div date-rangepicker class="flex items-center mt-4">
									<span class="mx-4 text-gray-500">from</span>

									<div class="relative">
										<div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"></div>
										<input
											type="date"
											className={
												"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
											}
											placeholder="Select the date"
										/>
									</div>
									<span class="mx-4 text-gray-500">to</span>

									<div class="relative">
										<input
											type="date"
											className={
												"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
											}
											placeholder="Select the date"
										/>
									</div>
								</div>
								<button
									className="inline-block px-6 py-3  text-white font-medium text-lg leading-tight  rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out max-w-xs mb-3 mt-5"
									type="button"
									data-mdb-ripple="true"
									data-mdb-ripple-color="light"
									style={{
										backgroundImage: "linear-gradient(to right, #C83131, #EF4444  )",
									}}
								>
									Check Now!
								</button>
							</div>
							<div>
								<div className=" text-dark-black font-medium text-lg mt-4 max-w-xs ">
									<button
										className="inline-block px-5 py-2 border border-red-600 text-red-600 font-medium text-lg leading-tight  rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out mb-3"
										type="button"
										data-mdb-ripple="true"
										data-mdb-ripple-color="light"
										onClick={() => props.history.push("/reimbursement-request")}
										// style={{
										//   backgroundImage:
										//     "linear-gradient(to right, #C83131, #EF4444  )",
										// }}
									>
										<div className="flex text-center">
											<img className="mr-2" src={reimburseIcon} /> Request Reimbursement
										</div>
									</button>
								</div>
							</div>
						</div>
					</WingBlank>
				</div>
			</div>
		</div>
	);
}
