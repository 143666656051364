import React, { Component, useState, useRef, useEffect } from "react";
import {
  NavBar,
  Icon,
  WingBlank,
  DatePicker,
  WhiteSpace,
  List,
} from "antd-mobile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faHome,
  faSpinner,
  faExclamationCircle,
  faExclamationTriangle,
  faInfo,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import "./index.scss";
import Axios from "axios";
import moment from "moment";
import { CSSTransition } from "react-transition-group";
import { getUserdata } from "../../Libs/Userdata";
import { dayLabels } from "../../Libs/Time";

export default function WorkSchedule(props) {
  /** State */
  const [dateFrom, setDateFrom] = useState(moment().add(-1, "month").toDate());
  const [dateTo, setDateTo] = useState(new Date());
  const [scrollHeight, setScrollHeight] = useState(500);
  const [loadingAttendances, setLoadingAttendances] = useState(false);
  const [attendanceData, setAttendanceData] = useState([]);
  const [errors, setErrors] = useState([]);
  const [loadingError, setLoadingError] = useState(false);
  const [pageShown, setPageShown] = useState(false);
  const [userData, setUserData] = useState(null);
  /** Refs */
  const attendanceSectionContainer = useRef(null);
  const attendanceSection = useRef(null);

  /** When page loads and container has heights */
  useEffect(() => {
    /** Transition */
    setUserData(getUserdata());
    setPageShown(true);
    return () => {
      setPageShown(false);
    };
  }, []);
  useEffect(() => {
    console.log(attendanceSectionContainer.current.clientHeight);
    setScrollHeight(attendanceSectionContainer.current?.clientHeight ?? 501);
    return () => {};
  }, [attendanceSectionContainer.current]);
  useEffect(() => {
    loadData();
    return () => {};
  }, [dateTo, dateFrom]);

  /** Functions */
  const loadData = () => {
    setLoadingAttendances(true);
    Axios.get(process.env.REACT_APP_SERVER_URL + "/schedule/my-schedule", {})
      .then((resp) => {
        if (resp.status == 200) {
          setLoadingError(false);
          setErrors([]);
          setAttendanceData(resp.data);
        }
      })
      .catch((e) => {
        setErrors([e]);
        setLoadingError(true);
      })
      .finally((e) => {
        setLoadingAttendances(false);
      });
  };

  return (
    <CSSTransition in={pageShown} classNames="alert" timeout={500}>
      <div>
        <NavBar
          mode="light"
          icon={
            <Icon
              type="left"
              onClick={(e) => {
                window.history.back();
              }}
            />
          }
          rightContent={[<Icon type="ellipsis" />]}
        >
          Work Schedule
        </NavBar>
        <WhiteSpace />

        <WingBlank size="md">
          <div className="sub-title">
            {loadingAttendances && (
              <React.Fragment>
                <FontAwesomeIcon spin icon={faSpinner} />{" "}
              </React.Fragment>
            )}
            Your Work Schedule
          </div>
          <WingBlank size="md">
            <p>
              The following schedules is based on your administrator's setting
              to your assigned division :{" "}
              <strong>{userData?.division?.division_name}</strong>
            </p>
          </WingBlank>
          {loadingError && (
            <div
              className="sub-title errors align-center"
              onClick={(e) => loadData()}
            >
              <FontAwesomeIcon icon={faExclamationTriangle} /> Cannot load your
              schedule. Tap to retry
            </div>
          )}
          {!loadingAttendances && !loadingError && attendanceData.length < 1 && (
            <div className="sub-title align-center" onClick={(e) => loadData()}>
              <FontAwesomeIcon icon={faInfoCircle} /> No data found for selected
              date range.
            </div>
          )}
        </WingBlank>
        <div
          className="schedule-section-container"
          ref={attendanceSectionContainer}
        >
          <div
            className="schedule-section"
            style={{
              height: scrollHeight,
            }}
            ref={attendanceSection}
          >
            {attendanceData.map((x) => (
              <ScheduleListItem schedule={x} />
            ))}
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}

function ScheduleListItem(props) {
  const isHeader = props.header != null;
  const { schedule } = props;
  return (
    <div className={"schedule-list-item" + (isHeader ? " header" : "")}>
      <div className="list-meta">
        <h4>{dayLabels[schedule.day_of_week]}</h4>
      </div>
      <div className="list-time">
        <span
          className={"enter-time " + (schedule.late_secs > 0 ? "late" : "ok")}
        >
          <FontAwesomeIcon
            icon={schedule.late_secs > 0 ? faExclamationCircle : faCheck}
          />{" "}
          {schedule.entry_at.substring(0, 5)}
        </span>
        <span
          className={
            "exit-time " + (schedule.exit_early_secs > 0 ? "early" : "ok")
          }
        >
          <FontAwesomeIcon
            icon={schedule.exit_early_secs > 0 ? faExclamationCircle : faHome}
          />{" "}
          {schedule.exit_at?.substring(0, 5) ?? "-"}
        </span>
      </div>
    </div>
  );
}
