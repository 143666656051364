import react, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import profilePict from "../../assets/logo-virusWhite.png";
import { getUserdata, verifyCurrentUserData } from "../../Libs/Userdata";

const Sidebar = (props) => {
  const [open, setOpen] = useState(true);
  const history = useHistory()
  const Menus = [
    { title: "MY ATTENDANCE", src: "attendance", url: "my_attendance" },
    { title: "REIMBURSEMENT", src: "reimbursement", url: "reimbursement" },
    { title: "REQUEST LEAVE", src: "leave", url: "leave/history" },
    { title: "PAYROLL SLIP ", src: "slip", url: "payroll" },
    { title: "APPROVAL HISTORY", src: "approval", url: "approval-history" },
  ];
  const [userData, setUserData] = useState(null);

  const user = getUserdata();

  useEffect(() => {
    if (window.innerWidth <= 760) {
      setOpen(false);
    }
    console.log(window.innerWidth);
  }, []);

  useEffect(() => {
    /** Getting current user data */
    const userdata = getUserdata();

    if (!userdata) {
      history.replace("/login");
    } else {
      setUserData(userdata);
      /** Verify User Data */
      verifyCurrentUserData()
        .then((e) => { })
        .catch((e) => {
          history.replace("/login?action=expired");
        });
    }

    return () => { };
  }, []);

  return (
    <>
      <div
        className="flex"
      // style={{ height: "auto", position: "fixed", float: "left", bottom: 0 }}
      >
        <div
          className={` ${open ? "w-72" : "w-20 "
            } bg-dark-black h-screen p-5  pt-8 relative duration-300`}
        >
          <img
            src={profilePict}
            className={`absolute cursor-pointer -right-3 top-9 w-7 border-dark-black
           border-2 rounded-full  ${!open && "rotate-180"}`}
            onClick={() => setOpen(!open)}
          />
          <div className="flex gap-x-4 items-center">
            <img
              src={profilePict}
              className={`w-10 h-10 rounded-full cursor-pointer duration-500 ${open && "rotate-[360deg]"
                }`}
            />
            <h1
              className={`text-white origin-left uppercase font-medium text-sm duration-200 ${!open && "scale-0"
                }`}
            >
              {/* {userData?.fullname} */}
              {user?.fullname}
              <p className="font-normal" style={{ fontSize: "12px" }}>
                {user?.division?.division_name}
              </p>
            </h1>
          </div>
          <ul className="pt-6">
            {Menus.map((Menu, index) => (
              <Link to={`/${Menu.url}`}>
                <li
                  key={index}
                  className={`flex  rounded-md p-2 cursor-pointer hover:bg-light-red text-gray-300 text-sm items-center gap-x-4 
              ${Menu.gap ? "mt-9" : "mt-2"} ${index === 0 && "bg-light-white"
                    } `}
                >
                  <img src={require(`../../assets/icons/${Menu.src}.png`)} />

                  <span
                    className={`${!open && "hidden"} origin-left duration-200`}
                  >
                    {Menu.title}
                  </span>
                </li>
              </Link>
            ))}
            <Link
              to={`/login`}
              onClick={(e) => {
                localStorage.removeItem("dentons_attendance_userdata");
              }}
            >
              <li
                key="5"
                className={`flex  rounded-md p-2 cursor-pointer hover:bg-light-red text-gray-300 text-sm items-center gap-x-4 mt-9`}
                onClick={(e) => {
                  props.history.push("/login");
                }}
              >
                <img src={require(`../../assets/icons/logoutBtn.png`)} />

                <span
                  className={`${!open && "hidden"} origin-left duration-200`}
                >
                  LOGOUT
                </span>
              </li>
            </Link>
          </ul>
        </div>
        {/* <div className="h-screen flex-1 p-7">
					<h1 className="text-2xl font-semibold ">Home Page</h1>
				</div> */}
      </div>
    </>
  );
};
export default Sidebar;
