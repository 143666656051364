import Axios from "axios";

export function saveUserData(userData) {
  console.log(`Saving `, userData)
  localStorage.setItem("dentons_attendance_userdata", JSON.stringify(userData));
}

export function getUserdata() {
  const userdataJson = localStorage.getItem("dentons_attendance_userdata");
  if (!userdataJson) return null;
  try {
    return JSON.parse(userdataJson);
  } catch (error) {
    console.error(error);
    return null;
  }
}

export function verifyCurrentUserData() {
  const userdata = getUserdata();
  return new Promise(async (res, rej) => {
    if (!userdata) {
      rej();
      return;
    }

    try {
      const resp = await Axios.get(
        process.env.REACT_APP_SERVER_URL + "/auth/authenticate-token"
      );
      if (resp.status == 200) {
        res();
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response.status, error.response.body);
      }
      rej();
    }
  });
}
