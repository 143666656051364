import React, { Component, useState, useEffect } from "react";
import {
  NavBar,
  Icon,
  WingBlank,
  NoticeBar,
  Button,
  Card,
  List,
  Result,
  Modal,
  Badge,
} from "antd-mobile";
import "./index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faCheckCircle,
  faCheckSquare,
  faExclamationCircle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "./Google";
import { Map, Marker, InfoWindow, Polygon } from "google-maps-react";
import CardHeader from "antd-mobile/lib/card/CardHeader";
import CardBody from "antd-mobile/lib/card/CardBody";
import Axios from "axios";
import { contains } from "../../../Libs/Raycast";
import { getUserdata } from "../../../Libs/Userdata";
import moment from "moment";
import createDateFromSchedule from "../../../Libs/ScheduleCalculation";
import { secondsToTimeInterval } from "../../../Libs/Time";
import { CSSTransition } from "react-transition-group";

function CheckInWFO(props) {
  /** State */
  const [officeLocations, setofficeLocations] = useState([]);
  const [detectedLocation, setDetectedLocation] = useState(null);
  const [checkingLocation, setCheckingLocation] = useState(false);
  const [detectedOffices, setDetectedOffices] = useState(null);
  const [currentScheduleWFO, setcurrentScheduleWFO] = useState([]);
  const [userData, setUserData] = useState(null);
  const [errors, setErrors] = useState([]);
  const [todayAttendance, setTodayAttendance] = useState(null);
  const [loadingTodayAttendance, setLoadingTodayAttendance] = useState(false);
  const [
    attendanceCompleteModalVisible,
    setAttendanceCompleteModalVisible,
  ] = useState(false);
  const [attendanceInfo, setAttendanceInfo] = useState(null);
  const [savingAttendance, setSavingAttendance] = useState(false);
  const [pageShown, setPageShown] = useState(false);

  /** Effect */
  useEffect(() => {
    /** Transition */
    setPageShown(true);
    let watchId = -1;
    if (navigator.geolocation) {
      console.log("Checking device location...");
      setCheckingLocation(true);
      watchId = navigator.geolocation.watchPosition(
        (locationSuccess) => {
          setDetectedLocation(locationSuccess);
          console.log(locationSuccess);
        },
        (positionError) => {
          console.log(positionError);
          setCheckingLocation(false);
        },
        {
          enableHighAccuracy: true,
          maximumAge: 30000,
        }
      );
    }
    Axios.get(process.env.REACT_APP_SERVER_URL + "/schedule/office-areas")
      .then((resp) => {
        setofficeLocations(resp.data);
      })
      .catch((e) => {})
      .finally((e) => {});
    const userData = getUserdata();
    console.log(userData);
    setUserData(userData);
    return () => {
      if (watchId != -1) navigator.geolocation.clearWatch(watchId);
      setPageShown(false);
    };
  }, []);
  useEffect(() => {
    if (!detectedLocation) return;
    setDetectedOffices(null);
    console.log(detectedLocation);
    for (let location of officeLocations) {
      location.geofences.sort((a, b) => a.leg - b.leg);
      const isLocationInsideOffice = contains(
        location.geofences.map((fence) => ({
          y: Number(fence.latitude),
          x: Number(fence.longitude),
        })),
        detectedLocation.coords.latitude,
        detectedLocation.coords.longitude
      );
      console.log(
        `Is location inside office `,
        location.office_area_name,
        ":",
        isLocationInsideOffice
      );
      if (isLocationInsideOffice) {
        setDetectedOffices(location);
        break;
      }
    }
    return () => {};
  }, [officeLocations, detectedLocation]);
  /** Calculate User Schedule */
  useEffect(() => {
    if (!userData) return;
    console.log("Setting up user schedule");
    /** Workhour : 0 for sunday, 6 for saturday  */
    const workHours = userData?.division?.workHours;
    console.log(userData);
    const dayOfWeek = moment().get("day");

    const workHourToday = workHours.filter(
      (x) => x.day_of_week == dayOfWeek && x.location === "wfo"
    );
    setcurrentScheduleWFO(workHourToday);
    if (!workHours) {
      setErrors(["Work Hour for current user not defined"]);
      return;
    }

    return () => {};
  }, [userData]);
  useEffect(() => {
    if (!userData) return;
    loadTodayAttendanceForUser();
    return () => {};
  }, [userData]);
  /** If today's attendance exists on server */
  useEffect(() => {
    if (!todayAttendance) return;

    return () => {};
  }, [todayAttendance]);
  /** Functions */
  const attendWFO = async () => {
    try {
      let selectedSchedule = null,
        early_secs = 0,
        late_secs = 0,
        workhour_start = null,
        workhour_end = null,
        exit_early_secs = 0,
        overtime_secs = 0;
      setSavingAttendance(true);
      if (!userData) {
        setErrors(["Userdata invalid!"]);
        return;
      }
      /**
       * Entry attendance
       * */
      if (!todayAttendance) {
        if (currentScheduleWFO.length > 0) {
          selectedSchedule = currentScheduleWFO[0];
        }
        /** User has schedule */
        if (selectedSchedule) {
          const now = moment();
          const scheduledEntryDate = createDateFromSchedule(
            now.clone(),
            selectedSchedule.entry_at
          );
          const diffInSecs = scheduledEntryDate.diff(now, "second");
          if (diffInSecs >= 0) early_secs = diffInSecs;
          if (diffInSecs < 0) late_secs = Math.abs(diffInSecs);

          workhour_start = selectedSchedule.entry_at;
        }
        const resp = await Axios.post(
          process.env.REACT_APP_SERVER_URL + "/attendance/attend/wfo",
          {
            user_code: userData.initial,
            fullname: userData.fullname,
            attendance_date: moment().format("YYYY-MM-DD HH:mm:ss"),
            entry_at: moment().format("YYYY-MM-DD HH:mm:ss"),
            exit_at: null,
            early_secs: early_secs,
            late_secs: late_secs,
            workhour_start: workhour_start,
            workhour_end: workhour_end,
            worktime_total_secs: -1,
            attendance_status: "pending",
            attendance_type: "wfo",
          }
        );

        if (resp.status == 200) {
          setAttendanceInfo(resp.data);
          setAttendanceCompleteModalVisible(true);
        }
      } else {
        /** Exit attendance */
        if (currentScheduleWFO.length > 0) {
          selectedSchedule = currentScheduleWFO[0];
        }
        /** User has schedule */
        if (selectedSchedule) {
          const now = moment();
          const scheduledExitDate = createDateFromSchedule(
            now.clone(),
            selectedSchedule.exit_at
          );
          const diffInSecs = scheduledExitDate.diff(now, "second");
          if (diffInSecs >= 0) exit_early_secs = diffInSecs;
          if (diffInSecs < 0) overtime_secs = Math.abs(diffInSecs);

          workhour_end = selectedSchedule.exit_at;

          const resp = await Axios.put(
            process.env.REACT_APP_SERVER_URL +
              "/attendance/attend/wfo/" +
              todayAttendance.id,
            {
              exit_at: moment().format("YYYY-MM-DD HH:mm:ss"),
              exit_early_secs,
              overtime_secs,
              workhour_end,
            }
          );

          if (resp.status == 200) {
            setAttendanceInfo(resp.data);

            setAttendanceCompleteModalVisible(true);
          }
        }
      }
      await loadTodayAttendanceForUser();
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        setSavingAttendance(false);
      }, 1000);
    }
  };
  const loadTodayAttendanceForUser = async () => {
    Axios.get(
      process.env.REACT_APP_SERVER_URL + "/attendance/today/" + userData.initial
    )
      .then((resp) => {
        setTodayAttendance(resp.data);
        setLoadingTodayAttendance(true);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally((e) => {
        setLoadingTodayAttendance(false);
      });
  };
  return (
    <CSSTransition in={pageShown} classNames="alert" timeout={500}>
      <div
        style={{ flex: 1, display: "flex", flexDirection: "column" }}
        className="attendance-wfo"
      >
        <NavBar
          mode="light"
          icon={
            <Icon
              type="left"
              onClick={(e) => {
                window.history.back();
              }}
            />
          }
          rightContent={[<Icon type="ellipsis" />]}
        >
          Work From Office
        </NavBar>
        <WingBlank size="md"></WingBlank>
        <div className="title">Presence by Office Location</div>
        <div className="maps-container">
          <Map
            google={props.google}
            zoom={18}
            center={{
              lat: detectedLocation?.coords.latitude,
              lng: detectedLocation?.coords.longitude,
            }}
          >
            <Marker
              name={"Current location"}
              position={{
                lat: detectedLocation?.coords.latitude,
                lng: detectedLocation?.coords.longitude,
              }}
            />
            {officeLocations.length > 0 &&
              officeLocations.map((location) => (
                <Polygon
                  paths={location.geofences.map((f) => {
                    return {
                      lat: Number(f.latitude),
                      lng: Number(f.longitude),
                    };
                  })}
                ></Polygon>
              ))}
          </Map>
          <Card className="checkin-information">
            <Card.Header
              title={
                detectedOffices
                  ? detectedOffices.office_area_name
                  : "Not Available"
              }
              thumb={
                detectedOffices ? (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color={"green"}
                    style={{ marginRight: 10 }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faExclamationCircle}
                    color={"orange"}
                    style={{ marginRight: 10 }}
                  />
                )
              }
            ></Card.Header>
            <Card.Body>
              {(!todayAttendance || todayAttendance?.exit_at == null) && (
                <React.Fragment>
                  {!detectedOffices && (
                    <h4 style={{ margin: 0 }}>You are not in office area</h4>
                  )}
                  {detectedOffices && (
                    <h4 style={{ margin: 0 }}>
                      You are currently in office area
                    </h4>
                  )}
                  {!detectedOffices && (
                    <p>
                      If you choose to check in now, your attendance will be
                      marked as <strong>Out of area</strong>. However it's up to
                      admin to approve or reject your attendance
                    </p>
                  )}
                  {detectedOffices && (
                    <p>
                      System detected that you are within your office area. You
                      can submit your attendance by clicking the button below
                    </p>
                  )}
                </React.Fragment>
              )}
              {todayAttendance?.exit_at != null && (
                <React.Fragment>
                  {!detectedOffices && (
                    <h4 style={{ margin: 0 }}>You are not in office area</h4>
                  )}
                  {detectedOffices && (
                    <h4 style={{ margin: 0 }}>
                      You are currently in office area
                    </h4>
                  )}
                  <p>
                    But we think you no longer want to do anything in this page
                    as you already doing good this day. Have a good evening!
                  </p>
                </React.Fragment>
              )}
              {currentScheduleWFO.length > 0 && (
                <p style={{ textAlign: "center" }}>
                  {loadingTodayAttendance ? (
                    <small style={{ textAlign: "center" }}>
                      <FontAwesomeIcon spin icon={faSpinner} /> Loading today's
                      attendance
                    </small>
                  ) : (
                    <small>Your schedule for today is :</small>
                  )}
                  <table className="attendance-table">
                    <tr>
                      <th>Entry</th>
                      <th>Exit</th>
                    </tr>
                    {currentScheduleWFO.map((x) => (
                      <tr>
                        <td>
                          {x.entry_at.substr(0, 5)}
                          <br />
                          {todayAttendance && (
                            <React.Fragment>
                              <Badge
                                text={todayAttendance.entry_at.substr(0, 5)}
                                style={{
                                  display: "block",
                                  padding: "0 3px",
                                  backgroundColor:
                                    todayAttendance.late_secs > 0
                                      ? "red"
                                      : "green",
                                  borderRadius: 2,
                                }}
                              />
                            </React.Fragment>
                          )}
                        </td>
                        <td>
                          {x.exit_at.substr(0, 5)} <br />
                          {todayAttendance?.exit_at && (
                            <React.Fragment>
                              <Badge
                                text={todayAttendance.exit_at.substr(0, 5)}
                                style={{
                                  display: "block",
                                  padding: "0 3px",
                                  backgroundColor:
                                    todayAttendance.exit_early_secs > 0
                                      ? "red"
                                      : "green",
                                  borderRadius: 2,
                                }}
                              />
                            </React.Fragment>
                          )}
                        </td>
                      </tr>
                    ))}
                  </table>
                </p>
              )}
              <List>
                <List.Item>
                  <Button
                    disabled={
                      savingAttendance ||
                      (todayAttendance && todayAttendance.exit_at != null)
                    }
                    size="small"
                    icon={
                      savingAttendance ? (
                        <FontAwesomeIcon spin icon={faSpinner} />
                      ) : null
                    }
                    type="primary"
                    onClick={(e) => {
                      attendWFO();
                    }}
                  >
                    {savingAttendance
                      ? "Saving..."
                      : !todayAttendance
                      ? "Report Attendance"
                      : todayAttendance.exit_at == null
                      ? "Finish my day"
                      : "That's it for today!"}
                  </Button>
                </List.Item>
              </List>
            </Card.Body>
          </Card>
          <Modal
            visible={attendanceCompleteModalVisible}
            transparent
            maskClosable={false}
            onClose={(e) => setAttendanceCompleteModalVisible(false)}
            title={"Attendance Information"}
            footer={[
              {
                text: "Close",
                onPress: (e) => {
                  setAttendanceCompleteModalVisible(false);
                },
              },
            ]}
          >
            <div
              style={{
                width: "100%",
                height: "auto",
              }}
            >
              <Result
                img={
                  <img
                    style={{ width: "100%" }}
                    src={require("../../../assets/attendance-complete.png")}
                  />
                }
                title={
                  attendanceInfo?.exit_at == null
                    ? "Entry Attendance Recorded"
                    : "Thanks for today!"
                }
                message={
                  <div>
                    Thank you. Your{" "}
                    {attendanceInfo?.exit_at == null ? "entry" : "exit"}{" "}
                    attendance has been recorded.
                    <br />
                    {attendanceInfo?.exit_at == null &&
                      attendanceInfo?.early_secs > 0 && (
                        <p>
                          According to schedule, you are{" "}
                          {secondsToTimeInterval(attendanceInfo.early_secs)}{" "}
                          seconds early!
                        </p>
                      )}
                    {attendanceInfo?.exit_at == null &&
                      attendanceInfo?.late_secs > 0 && (
                        <p>
                          According to schedule, you are{" "}
                          {secondsToTimeInterval(attendanceInfo.late_secs)}{" "}
                          seconds late!
                        </p>
                      )}
                    {attendanceInfo?.exit_at != null &&
                      attendanceInfo?.exit_early_secs > 0 && (
                        <p>
                          According to schedule, you went home{" "}
                          {secondsToTimeInterval(
                            attendanceInfo.exit_early_secs
                          )}{" "}
                          seconds early!
                        </p>
                      )}
                    {attendanceInfo?.exit_at != null &&
                      attendanceInfo?.overtime_secs > 0 && (
                        <p>
                          According to schedule, you are{" "}
                          {secondsToTimeInterval(attendanceInfo.overtime_secs)}{" "}
                          seconds late!
                        </p>
                      )}
                  </div>
                }
              />
            </div>
          </Modal>
        </div>
      </div>
    </CSSTransition>
  );
}

export default Wrapper(CheckInWFO);
