import React, { Component, useState, useRef, useEffect } from "react";
import {
  NavBar,
  Icon,
  WingBlank,
  DatePicker,
  WhiteSpace,
  List,
} from "antd-mobile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faHome,
  faSpinner,
  faExclamationCircle,
  faExclamationTriangle,
  faInfo,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import "./index.scss";
import Axios from "axios";
import moment from "moment";
import { CSSTransition } from "react-transition-group";
import Sidebar from "../../Components/Sidebar";
import alertMsg from "../../assets/icons/alertMsg.svg";
import successMsg from "../../assets/icons/success.svg";
import attendanceBtn from "../../assets/icons/attendanceBtn.svg";
import { getUserdata } from "../../Libs/Userdata";

export default function MyAttendance(props) {
  /** State */
  const [dateFrom, setDateFrom] = useState(moment().add(-1, "month").toDate());
  const [dateTo, setDateTo] = useState(new Date());
  const [scrollHeight, setScrollHeight] = useState(500);
  const [loadingAttendances, setLoadingAttendances] = useState(false);
  const [attendanceData, setAttendanceData] = useState([]);
  const [todayAttendance, setTodayAttendance] = useState(null);
  const [errors, setErrors] = useState([]);
  const [loadingError, setLoadingError] = useState(false);
  const [pageShown, setPageShown] = useState(false);
  const [value, onChange] = useState(new Date());
  /** Refs */
  const attendanceSectionContainer = useRef(null);
  const attendanceSection = useRef(null);

  let userData = getUserdata();
  /** When page loads and container has heights */
  useEffect(() => {
    /** Transition */
    setPageShown(true);
    return () => {
      setPageShown(false);
    };
  }, []);
  useEffect(() => {
    console.log(attendanceSectionContainer.current.clientHeight);
    setScrollHeight(attendanceSectionContainer.current?.clientHeight ?? 501);
    return () => {};
  }, [attendanceSectionContainer.current]);
  useEffect(() => {
    loadData();
    return () => {};
  }, [dateTo, dateFrom]);

  /** Functions */

  const loadData = () => {
    setLoadingAttendances(true);
    Axios.get(process.env.REACT_APP_SERVER_URL + "/attendance/my-attendance", {
      params: {
        dateFrom: dateFrom,
        dateTo: dateTo,
      },
    })
      .then((resp) => {
        console.log("testing", resp);
        if (resp.status == 200) {
          setLoadingError(false);
          setErrors([]);
          setAttendanceData(resp.data);
        }
      })
      .catch((e) => {
        setErrors([e]);
        setLoadingError(true);
      })
      .finally((e) => {
        setLoadingAttendances(false);
      });
  };

  const loadTodayAttendance = () => {
    /** Get today's attendance */
    // setLoadingTodayAttendance(true);
    Axios.get(
      process.env.REACT_APP_SERVER_URL + "/attendance/today/" + userData.initial
    )
      .then((resp) => {
        setTodayAttendance(resp.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (userData) {
      loadTodayAttendance();
    }
    return () => {};
  }, [userData]);

  return (
    // <CSSTransition in={pageShown} classNames="alert" timeout={500}>
    <div>
      <div className="flex">
        <Sidebar />
        <div className="h-screen flex-1 p-7">
          <WingBlank size="md">
            <div
              className={`alertMessage flex bg-white ${
                todayAttendance
                  ? "border-green-500 text-green-600 w-80"
                  : "border-amber-400 text-amber-400 w-96"
              }  border p-2 rounded drop-shadow-md `}
              style={{ fontSize: "12px" }}
            >
              {todayAttendance ? (
                <>
                  <div className="mr-2">
                    <img src={successMsg}></img>
                  </div>
                  <div>
                    <p>
                      Your Attendance has been confirmed for today at{" "}
                      {attendanceData ? attendanceData[0].entry_at : ""} Thank
                      You!
                    </p>
                    {/* <p></p> */}
                  </div>
                </>
              ) : (
                <>
                  <div className="mr-2">
                    <img src={alertMsg}></img>
                  </div>
                  <div>
                    <p>Your Attendance has not yet been confirmed for today</p>
                    <p>
                      Please Confirm Your Attendance to confirm your Attendance!
                    </p>
                  </div>
                </>
              )}
            </div>
            <div className="flex justify-between md:flex-row flex-col gap-2 mt-8">
              <div>
                <div className=" text-dark-black font-bold text-lg mt-4 mb-4">
                  Check My Attendance
                </div>
                <div date-rangepicker class="flex items-center ">
                  <div className="date-from">
                    <span class="text-sm font-medium text-gray-900">from</span>

                    <div class="relative mt-2">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"></div>
                      <input
                        type="date"
                        // value={dateFrom}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setDateFrom(e.target.value);
                        }}
                        className={
                          "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full  p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                        }
                        placeholder="Select the date"
                      />
                    </div>
                  </div>
                  <div className="date-to ml-4">
                    <span class="text-sm font-medium text-gray-900">to</span>

                    <div class="relative mt-2">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"></div>
                      <input
                        type="date"
                        // value={dateFrom}
                        onChange={(e) => {
                          setDateTo(e.target.value);
                        }}
                        className={
                          "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full  p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                        }
                        placeholder="Select the date"
                      />
                    </div>
                  </div>
                </div>
                {console.log(attendanceData)}

                {/* {attendanceData.length > 0 ? (
                  <div className="max-w-lg align-middle">
                    <img
                      className="max-w-full h-auto rounded-lg"
                      src={successMsg}
                      alt="image description"
                    />
                    <figcaption className="mt-2 text-sm text-center text-gray-500 dark:text-gray-400">
                      {attendanceData[0].attendance_date}
                    </figcaption>
                  </div>
                ) : (
                  ""
                )} */}

                <List className="date-picker-list">
                  {/* <DatePicker
										mode="date"
										value={dateFrom}
										onChange={(date) => {
											setDateFrom(date);
										}}
									>
										<List.Item>From</List.Item>
									</DatePicker>
									{console.log("testing", attendanceData)}
									<DatePicker
										mode="date"
										value={dateTo}
										onChange={(date) => {
											setDateTo(date);
										}}
									>
										<List.Item>To</List.Item>
									</DatePicker> */}
                </List>
                <div className="attendance-calendar"></div>
                <div className="">
                  {loadingAttendances && (
                    <React.Fragment>
                      <FontAwesomeIcon spin icon={faSpinner} />{" "}
                    </React.Fragment>
                  )}
                  {/* <h2>My Attendance</h2> */}
                </div>
                {loadingError && (
                  <div
                    className="sub-title errors align-center"
                    onClick={(e) => loadData()}
                  >
                    <FontAwesomeIcon icon={faExclamationTriangle} /> Cannot load
                    your attendance history. Tap to retry
                  </div>
                )}
                {!loadingAttendances &&
                  !loadingError &&
                  attendanceData.length < 1 && (
                    <div
                      className="sub-title align-center"
                      onClick={(e) => loadData()}
                    >
                      <FontAwesomeIcon icon={faInfoCircle} /> No data found for
                      selected date range.
                    </div>
                  )}
                <button
                  className="inline-block px-6 py-3  text-white font-medium text-lg leading-tight  rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out max-w-xs mb-3 mt-5"
                  type="button"
                  data-mdb-ripple="true"
                  data-mdb-ripple-color="light"
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, #C83131, #EF4444  )",
                  }}
                >
                  Check Now!
                </button>
              </div>
              <div className="">
                <div className="text-dark-black font-medium text-lg ">
                  <button
                    className="inline-block px-5 py-2 border border-red-600 text-red-600 font-medium text-lg leading-tight  rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out  mb-3"
                    type="button"
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="light"
                    onClick={() => props.history.push("/checkin_wfh")}
                    // style={{
                    //   backgroundImage:
                    //     "linear-gradient(to right, #C83131, #EF4444  )",
                    // }}
                  >
                    <div className="flex">
                      <img src={attendanceBtn} className="mr-2" /> Confirm Your
                      Attendance
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </WingBlank>
          <div
            className="attendance-section-container"
            ref={attendanceSectionContainer}
          >
            <div
              className="attendance-section"
              style={{
                height: 0,
              }}
              ref={attendanceSection}
            >
              {attendanceData?.map((x) => (
                <AttendanceListItem attendance={x} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
    // </CSSTransition>
  );
}

function AttendanceListItem(props) {
  const isHeader = props.header != null;
  const { attendance } = props;
  return (
    <div className={"attendance-list-item" + (isHeader ? " header" : "")}>
      <div className="list-meta">
        <h4>{moment(attendance.attendance_date).format("ddd, DD MMM YYYY")}</h4>
        <h5>
          {attendance.attendance_type == "wfh"
            ? "Work From Home"
            : "Work From Office"}{" "}
          {/* {attendance.attendance_status == "pending" && (
            <small
              style={{
                color: "white",
                backgroundColor: "orange",
                padding: "2px 4px",
                borderRadius: "3px",
              }}
            >
              {attendance.attendance_status?.toUpperCase()}
            </small>
          )} */}
        </h5>
      </div>
      <div className="list-time">
        <span
          className={"enter-time " + (attendance.late_secs > 0 ? "late" : "ok")}
        >
          <FontAwesomeIcon
            icon={attendance.late_secs > 0 ? faExclamationCircle : faCheck}
          />{" "}
          {attendance.entry_at.substring(0, 5)}
        </span>
        {/* <span
          className={
            "exit-time " + (attendance.exit_early_secs > 0 ? "early" : "ok")
          }
        >
          <FontAwesomeIcon
            icon={attendance.exit_early_secs > 0 ? faExclamationCircle : faHome}
          />{" "}
          {attendance.exit_at?.substring(0, 5) ?? "-"}
        </span> */}
      </div>
    </div>
  );
}
