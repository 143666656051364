export function secondsToTimeInterval(secs) {
  const day = parseInt(secs / (24 * 60 * 60));
  if (day > 0) secs -= day * (24 * 60 * 60);
  const hour = parseInt(secs / (60 * 60));
  if (hour > 0) secs -= hour * (60 * 60);
  const minute = parseInt(secs / 60);
  if (minute > 0) secs -= minute * 60;

  let strarr = [];
  if (day > 0) strarr.push(`${day} day(s)`);
  if (hour > 0) strarr.push(`${hour} hour(s)`);
  if (minute > 0) strarr.push(`${minute} minute(s)`);
  strarr.push(`${secs} second(s)`);

  return strarr.join(" ");
}

export const dayLabels = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
