import React, { Component, useEffect, useState } from "react";
import moment from "moment";
import { CSSTransition } from "react-transition-group";
import Sidebar from "../../../Components/Sidebar";
import { DatePicker, Icon, List, NavBar, WhiteSpace, WingBlank } from "antd-mobile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faInfoCircle, faSpinner } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import leaveBtn from "../../../assets/icons/leaveBtn.svg";

import "./index.scss";
export default function LeaveHistory(props) {
	/** State */
	const [pageShown, setPageShown] = useState(false);
	const [dateFrom, setDateFrom] = useState(moment().add(-1, "month").toDate());
	const [dateTo, setDateTo] = useState(new Date());
	const [loadingLeaves, setLoadingLeaves] = useState(false);
	const [loadingError, setLoadingError] = useState(false);
	const [loadingErrorMessage, setLoadingErrorMessage] = useState(null);
	const [leaveData, setLeaveData] = useState([]);

	/** Effect */
	useEffect(() => {
		/** Page Transition */
		setPageShown(true);
		loadLeaves();
		return () => {
			setPageShown(false);
		};
	}, []);
	useEffect(() => {
		loadLeaves();
		return () => { };
	}, [dateFrom, dateTo]);

	/** Functions */
	const loadLeaves = async () => {
		setLoadingLeaves(true);
		try {
			const resp = await Axios.get(process.env.REACT_APP_SERVER_URL + "/leave/my-leaves", {
				params: {
					from: moment(dateFrom).format("YYYY-MM-DD"),
					to: moment(dateTo).format("YYYY-MM-DD"),
				},
			});
			if (resp.status == 200) {
				setLoadingError(false);
				setLoadingErrorMessage(null);
				setLeaveData(resp.data);
			}
		} catch (error) {
			setLoadingError(true);
			setLoadingErrorMessage(error.message);
		} finally {
			setLoadingLeaves(false);
		}
	};
	return (
		<div>
			<div className="flex">
				<Sidebar />
				<div className="h-screen flex-1 p-7">
					<WingBlank size="md">
						<div className="flex justify-between md:flex-row flex-col gap-2 mt-8">
							<div>
								<div className=" text-dark-black font-bold text-lg mt-4">Check My Leave History</div>
								<div date-rangepicker class="flex items-center ">
									<div className="date-from">
										<span class="text-sm font-medium text-gray-900">from</span>

										<div class="relative mt-2">
											<div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"></div>
											<input
												type="date"
												// value={dateFrom}
												onChange={(e) => {
													setDateFrom(e.target.value);
												}}
												className={
													"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full  p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
												}
												placeholder="Select the date"
											/>
										</div>
									</div>
									<div className="date-to ml-4">
										<span class="text-sm font-medium text-gray-900">to</span>

										<div class="relative mt-2">
											<div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"></div>
											<input
												type="date"
												// value={dateFrom}
												onChange={(e) => {
													setDateTo(e.target.value);
												}}
												className={
													"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full  p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
												}
												placeholder="Select the date"
											/>
										</div>
									</div>
								</div>
							</div>
							<div>
								<div className=" text-dark-black font-medium text-lg mt-4 max-w-xs ">
									<button
										className="inline-block px-5 py-2 border border-red-600 text-red-600 font-medium text-lg leading-tight  rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out mb-3"
										type="button"
										data-mdb-ripple="true"
										data-mdb-ripple-color="light"
										onClick={() => props.history.push("/leave/request")}
									// style={{
									//   backgroundImage:
									//     "linear-gradient(to right, #C83131, #EF4444  )",
									// }}
									>
										<div className="flex text-center">
											<img className="mr-2" src={leaveBtn} /> Request Leave
										</div>
									</button>
								</div>
							</div>
						</div>

						{/* <List className="date-picker-list">
								<DatePicker
									mode="date"
									value={dateFrom}
									onChange={(date) => {
										setDateFrom(date);
									}}
								>
									<List.Item>From</List.Item>
								</DatePicker>
								<DatePicker
									mode="date"
									value={dateTo}
									onChange={(date) => {
										setDateTo(date);
									}}
								>
									<List.Item>To</List.Item>
								</DatePicker>
							</List> */}
					</WingBlank>
					<div className="leave-section-container border-2 border-gray border-radius mt-4">
						<div className="leave-section">
							{leaveData.map((x) => (
								<LeaveListItem leave={x} />
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
function LeaveListItem(props) {
	const isHeader = props.header != null;
	const { leave } = props;
	return (
		<div className={"leave-list-item" + (isHeader ? " header" : "")}>
			<div className="list-meta">
				<h4>{moment(leave.start).format("ddd, DD MMM YYYY")}</h4>
				<p>{leave.reason}</p>
			</div>
			<div className="list-time">
				<span className={"enter-time " + (leave.approved == 0 ? "late" : "ok")}>
					{leave.approved == 0 ? "Pending Approval" : leave.approved == 1 ? "Approved" : "Rejected"}
				</span>
			</div>
		</div>
	);
}
