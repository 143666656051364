import { faCheckDouble, faInfoCircle, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Sidebar from "../../../Components/Sidebar";

import {
	Button,
	DatePicker,
	Icon,
	List,
	Modal,
	NavBar,
	Picker,
	Result,
	TextareaItem,
	WhiteSpace,
	WingBlank,
} from "antd-mobile";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import moment from "moment";
import { getUserdata } from "../../../Libs/Userdata";
export default function LeaveRequest() {
	/** State */
	const [pageShown, setPageShown] = useState(false);
	const [dateFrom, setDateFrom] = useState(new Date());
	const [dateTo, setDateTo] = useState(new Date());
	const [leaveCategories, setLeaveCategories] = useState([]);
	const [leaveHistory, setLeaveHistory] = useState([]);
	const [leaveAllowance, setLeaveAllowance] = useState(null);
	const [modalSendingVisible, setModalSendingVisible] = useState(false);
	const [savingData, setSavingData] = useState(false);
	const [saveSuccess, setSaveSuccess] = useState(false);
	const [savedData, setSavedData] = useState(null);
	/** Form selection */
	const [selectedLeaveCategory, setSelectedLeaveCategory] = useState(null);
	const [leaveReason, setLeaveReason] = useState("");
	/** Form Errors */
	const [categoryErrors, setCategoryErrors] = useState([]);
	const [dateErrors, setDateErrors] = useState([]);
	const [reasonErrors, setReasonErrors] = useState([]);
	const [saveErrors, setSaveErrors] = useState([]);
	/** Effects */
	useEffect(() => {
		setPageShown(true);
		loadLeaveCategories();
		loadLeaveAllowance();
		return () => {
			setPageShown(false);
		};
	}, []);
	useEffect(() => {
		if (!selectedLeaveCategory) {
			setCategoryErrors(["You have to specify leave categories!"]);
		}
		return () => {
			setCategoryErrors([]);
		};
	}, [selectedLeaveCategory]);
	useEffect(() => {
		const err = [];
		if (moment().diff(moment(dateFrom), "seconds") > 0) {
			err.push(`You cannot ask for leave for the past!`);
		}

		setDateErrors(err);
		return () => {
			setDateErrors([]);
		};
	}, [dateFrom, dateTo]);
	useEffect(() => {
		if (leaveReason.length < 5) {
			setReasonErrors(["Leave reason must have length of 5 or more!"]);
		}
		return () => {
			setReasonErrors([]);
		};
	}, [leaveReason]);
	/** functions */
	const loadLeaveCategories = async () => {
		try {
			const resp = await Axios.get(process.env.REACT_APP_SERVER_URL + "/administration/leave_categories");
			if (resp.status == 200) {
				setLeaveCategories(resp.data.map((x) => ({ ...x, value: x.id, label: x.leave_category })));
			}
		} catch (error) { }
	};
	const loadLeaveAllowance = async () => {
		try {
			const resp = await Axios.get(process.env.REACT_APP_SERVER_URL + "/administration/leave_allowance");
			if (resp.status == 200) {
				const { limit, history } = resp.data;
				setLeaveHistory(history);
				setLeaveAllowance(limit);
			}
		} catch (error) { }
	};
	const getUserAllowanceInformation = () => {
		if (!leaveAllowance) return <React.Fragment></React.Fragment>;
		const { leave_limit: limit } = leaveAllowance;
		const usage = leaveHistory.reduce((prev, next) => prev + next.days, 0);
		return (
			<p className="align-center">
				<FontAwesomeIcon icon={faInfoCircle} /> You used {usage}/{limit} of your allowance this year
			</p>
		);
	};
	const createLeaveRequest = async () => {
		try {
			setSavingData(true);
			setModalSendingVisible(true);
			const userdata = getUserdata();
			if (!userdata) {
				alert(`User data null!`);
				return;
			}
			let resps = "OTHER";
			if (userdata.responsibilities.length > 1) {
				resps = userdata.responsibilities[0].responsibility_code;
			}
			console.log(selectedLeaveCategory)
			const data = {
				user_code: userdata.initial,
				resps: resps,
				leave_request_category_id: selectedLeaveCategory,
				reason: leaveReason,
				start: moment(dateFrom).format("YYYY-MM-DD 00:00:00"),
				end: moment(dateFrom).format("YYYY-MM-DD 23:59:59"),
				leave_year_periode: moment().format("YYYY"),
			};
			const resp = await Axios.post(process.env.REACT_APP_SERVER_URL + "/leave/new", data);
			if (resp.status == 200) {
				setSaveSuccess(true);
				setSavedData(resp.data);
			}
		} catch (error) {
			setSaveErrors([error.message]);
		} finally {
			setSavingData(false);
		}
	};

	return (
		<div>
			<div className="flex">
				<Sidebar />
				<div className="h-screen flex-1 p-7">
					<WingBlank size="md">
						<div className=" text-dark-black font-bold text-lg mt-4 mb-5">Request Leave</div>
						<label for="leave" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
							Leave Category
						</label>
						<select
							id="leave"
							className=" border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500 max-w-xs"
							onChange={(e) => setSelectedLeaveCategory(e.target.value)}
						>
							<option value={null}>Pilih kategori</option>
							{leaveCategories.map((x) => (
								<option value={x.id}>{x.label}</option>
							))}
						</select>

						{categoryErrors.map((x) => (
							<div className="errors mt-1 text-red-500">{x}</div>
						))}
						<label for="leave" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white mt-4">
							Pick a Leave Date
						</label>

						<div date-rangepicker class="flex items-center ">
							<div className="date-from">
								<span class="text-sm font-medium text-gray-900">from</span>

								<div class="relative mt-2">
									<div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"></div>
									<input
										type="date"
										// value={dateFrom}
										onChange={(e) => {
											setDateFrom(e.target.value);
										}}
										className={
											"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full  p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
										}
										placeholder="Select the date"
									/>
								</div>
							</div>
							<div className="date-to ml-4">
								<span class="text-sm font-medium text-gray-900">to</span>

								<div class="relative mt-2">
									<div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"></div>
									<input
										type="date"
										// value={dateFrom}
										// onChange={(e) => {
										// 	setDateFrom(e.target.value);
										// }}
										className={
											"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full  p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
										}
										placeholder="Select the date"
									/>
								</div>
							</div>
						</div>
						{console.log("=====", dateFrom)}
						{/* <DatePicker
								mode="date"
								value={dateFrom}
								onChange={(date) => {
									console.log("xxxxxx", date);
									setDateFrom(date);
								}}
							>
								<List.Item>Date</List.Item>
							</DatePicker> */}

						{dateErrors.map((x) => (
							<div className="errors mt-1 text-red-500">{x}</div>
						))}

						<div className="mt-4 mb-2">
							<label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
								State your reason
							</label>
							<textarea
								value={leaveReason}
								onChange={(e) => setLeaveReason(e.target.value)}
								placeholder="Write it clearly here"
								id="message"
								rows="4"
								className=" border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 max-w-xs"
								required
							></textarea>
						</div>
						{reasonErrors.map((x) => (
							<div className="errors text-red-500">{x}</div>
						))}
						{getUserAllowanceInformation()}
						<br />
						<Button onClick={() => {
							createLeaveRequest();
						}}>Create Leave Request</Button>
					</WingBlank>
					<Modal
						maskClosable={true}
						transparent
						visible={modalSendingVisible}
						onClose={(e) => {
							setModalSendingVisible(false);
							if (saveSuccess) {
								window.history.back();
							}
						}}
						footer={[
							{
								text: "Close",
								onPress: (e) => {
									setModalSendingVisible(false);
									if (saveSuccess) {
										window.history.back();
									}
								},
							},
						]}
					>
						{savingData && (
							<React.Fragment>
								<div
									style={{
										width: "100%",
										height: "auto",
										fontSize: 60,
									}}
								>
									<FontAwesomeIcon spin icon={faSpinner} size={60} color={"green"} />
								</div>
								<p className="align-center">Registering your leave request</p>
							</React.Fragment>
						)}
						{!savingData && saveSuccess && (
							<Result
								img={<img style={{ width: "100%" }} src={require("../../../assets/attendance-complete.png")} />}
								title={"Request Saved"}
								message={
									<div>Your leave request has been recorded. It's now up to your admin to approve or cancel your request.</div>
								}
							/>
						)}
						{saveErrors.map((x) => (
							<p className="align-center errors">{x}</p>
						))}
					</Modal>
				</div>
			</div>
		</div>
	);
}
