import React, { Component, useState } from "react";
import logo from "../../assets/logo-virus.png";
import logoWhite from "../../assets/logo-virusWhite.png";
import "./index.scss";
import { WhiteSpace, TextareaItem, WingBlank, Card, List, InputItem, Button, NoticeBar } from "antd-mobile";
import { faUser, faKey, faSign, faSignInAlt, faSpinner, faExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";
import { saveUserData } from "../../Libs/Userdata";
export default function Login(props) {
	/** State */
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [isLoggingIn, setIsLoggingIn] = useState(false);
	const [error, setError] = useState([]);

	console.log(props);

	/** Functions */
	const login = async () => {
		try {
			setError([]);
			setIsLoggingIn(true);
			const resp = await Axios.post(process.env.REACT_APP_SERVER_URL + "/auth/login", {
				username,
				password,
			});
			if (resp.status == 200) {
				saveUserData(resp.data);
				props.history.replace("/my_attendance");
			}
		} catch (error) {
			if (error.response) {
				if (error.response.status == 400) {
					setError([<span>Employee Data for user {username} empty.</span>]);
				}
				if (error.response.status == 403) {
					setError([`Your username : ${username} has not been registered into system`]);
				}
				if (error.response.status == 500) {
					setError(["Server Failure"]);
				}
			}
		} finally {
			setIsLoggingIn(false);
		}
	};
	return (
		<>
			<section className="h-full gradient-form bg-[#F69E9E] md:h-screen">
				<div className="py-12 px-6 h-full">
					<div className="xl:w-10/12 m-auto">
						<div className="block bg-white shadow-lg rounded-lg">
							<div className="lg:flex lg:flex-wrap g-0">
								<div className="lg:w-5/12 px-4 md:px-0">
									<div className="md:p-12 md:mx-6">
										<div className="text-center">
											<img className="mx-auto w-24" src={logo} alt="logo" />
										</div>
										<form>
											<p className="mb-4 text-center text-lg font-bold" style={{ color: "#10002E" }}>
												Welcome to Virus <br /> Attendance!
											</p>
											{error.length > 0 &&
												error.map((x) => (
													<div
														class="mb-2 bg-red-100 border border-red-400 text-red-700 px-2 py-2 rounded relative text-sm"
														role="alert"
													>
														<strong class="font-bold">Error!</strong>
														<span class="block sm:inline"> {x}</span>
													</div>
												))}
											<div className="mb-4">
												<label className="text-label font-semibold" for="username">
													Username
												</label>
												<InputItem
													type="text"
													style={{ width: "100%", height: "100%" }}
													className="mt-2 form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
													id="exampleFormControlInput1"
													value={username}
													onChange={(e) => setUsername(e)}
													placeholder="Enter your username"
												/>
											</div>
											<div className="mb-4">
												<label className="text-label font-semibold" for="username">
													Password
												</label>
												<InputItem
													type="password"
													className="mt-2 form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
													id="exampleFormControlInput1"
													value={password}
													placeholder="Enter your password"
													onChange={(e) => setPassword(e)}
												/>
											</div>
											<div className="pt-1 mb-12 pb-1">
												<button
													className="inline-block px-6 py-3  text-white font-medium text-lg leading-tight  rounded shadow-md  hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out w-full mb-3"
													type="button"
													data-mdb-ripple="true"
													data-mdb-ripple-color="light"
													style={{
														backgroundImage: "linear-gradient(to right, #C83131, #EF4444  )",
													}}
													icon={isLoggingIn ? <FontAwesomeIcon icon={faSpinner} spin /> : <FontAwesomeIcon icon={faSignInAlt} />}
													onClick={(e) => {
														login();
													}}
												>
													Login
												</button>
												<a className="text-red-500 underline" href="#!">
													Forgot password?
												</a>
											</div>
										</form>
									</div>
								</div>
								<div
									className="lg:w-7/12 flex items-center lg:rounded-r-lg rounded-b-lg lg:rounded-bl-none"
									style={{
										backgroundImage: "linear-gradient(to right, #C83131, #EF4444  )",
									}}
								>
									<div class="text-white py-12 m-auto text-center">
										<img src={logoWhite} alt="logo" className="max-w-[100px]" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <div className="login-screen">
				<div className="center-content">
					<div className="logo-container">
						<img src={logo} />
					</div>
					<div className="presence-title">Attendance Portal</div>

					<WhiteSpace />

					<WingBlank size="md">
						{error.length > 0 &&
							error.map((x) => (
								<NoticeBar icon={<FontAwesomeIcon icon={faExclamation} />} marqueeProps={{}}>
									{x}
								</NoticeBar>
							))}
						<List renderHeader={() => "Login"}>
							<InputItem clear placeholder="Username" value={username} onChange={(e) => setUsername(e)}>
								<FontAwesomeIcon icon={faUser} />
							</InputItem>
							<InputItem clear type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e)}>
								<FontAwesomeIcon icon={faKey} />
							</InputItem>
							<List.Item>
								<Button
									style={{ float: "right" }}
									type="primary"
									size="small"
									inline
									icon={isLoggingIn ? <FontAwesomeIcon icon={faSpinner} spin /> : <FontAwesomeIcon icon={faSignInAlt} />}
									onClick={(e) => {
										login();
									}}
								>
									Log In
								</Button>
							</List.Item>
						</List>
					</WingBlank>
				</div>
			</div> */}
		</>
	);
}
