import {
  faCamera,
  faInfo,
  faSpinner,
  faExclamationTriangle,
  faCheckCircle,
  faSignInAlt,
  faSignOutAlt,
  faInfoCircle,
  faLocationArrow,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Modal,
  WingBlank,
  NavBar,
  Icon,
  Card,
  Result,
} from "antd-mobile";
import Axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { getUserdata } from "../../../Libs/Userdata";
import "./index.scss";
import moment from "moment";
import { CSSTransition } from "react-transition-group";
import { contains } from "../../../Libs/Raycast";
import createDateFromSchedule from "../../../Libs/ScheduleCalculation";
import logo from "../../../assets/logo-virus-horizontal-label.png";

import cv from "@techstark/opencv-js";
import { loadHaarFaceModels, detectHaarFace } from "./opencv/haarFaceDetection";
import Webcam from "react-webcam";
import { Link } from "react-router-dom";

let stream = null;
let countHasFace = 0
let hasFace = false;

const selfieCameraConstraint = {
  video: {
    width: {
      max: 1280,
    },
    height: {
      max: 720,
    },
  },
};

export default function CheckInWFH(props) {
  /** State */
  const [startingCamera, setStartingCamera] = useState(false);
  const [notificationModalVisible, setNotificationModalVisible] =
    useState(false);
  const [verificationModalVisible, setVerificationModalVisible] =
    useState(false);
  const [infoText, setInfoText] = useState(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [uploadingAttendance, setUploadingAttendance] = useState(false);
  const [loadingTodayAttendance, setLoadingTodayAttendance] = useState(false);
  const [attendanceResult, setAttendanceResult] = useState(null);
  const [currentScheduleWFH, setcurrentScheduleWFH] = useState([]);
  const [todayAttendance, setTodayAttendance] = useState(null);
  const [userData, setUserData] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [displayOnly, setDisplayOnly] = useState(false);
  const [displayAttendance, setDisplayAttendance] = useState("entry");
  const [pageShown, setPageShown] = useState(false);
  const [errors, setErrors] = useState([]);
  const [officeLocations, setofficeLocations] = useState([]);
  const [detectedLocation, setDetectedLocation] = useState(null);
  const [checkingLocation, setCheckingLocation] = useState(false);
  const [detectedOffices, setDetectedOffices] = useState([]);
  const [faceData, setFaceData] = useState(null);
  const [isPlaying, setIsPlaying] = useState(!!stream);
  const [allowCapture, setAllowCapture] = useState(false)

  // Open CV
  const [modelLoaded, setModelLoaded] = React.useState(false);

  useEffect(() => {
    loadHaarFaceModels().then(() => {
      setModelLoaded(true);
    });
  }, []);

  const webcamRef = React.useRef(null);
  const imgRef = React.useRef(null);
  const faceImgRef = React.useRef(null);

  React.useEffect(() => {
    if (!modelLoaded) return;

    const detectFace = async () => {
      const imageSrc = webcamRef.current.getScreenshot();
      if (!imageSrc) return;

      return new Promise((resolve) => {
        imgRef.current.src = imageSrc;

        imgRef.current.onload = () => {
          try {
            const img = cv.imread(imgRef.current);
            let dataDetect = detectHaarFace(img);
            setFaceData(dataDetect.faceData);
            // console.log("==halo====", dataDetect);
            cv.imshow(faceImgRef.current, img);

            img.delete();
            resolve();
          } catch (error) {
            console.log(error);
            resolve();
          }
        };
      });
    };

    /** Effect */
    let handle;
    const nextTick = () => {
      handle = requestAnimationFrame(async () => {
        await detectFace();
        nextTick();
      });
    };
    nextTick();
    return () => {
      cancelAnimationFrame(handle);
    };
  }, [modelLoaded]);

  /** Effect */
  useEffect(() => {
    hasFace = faceData == 1

    return () => {

    }
  }, [faceData])

  useEffect(() => {
    setPageShown(true);
    setStartingCamera(true);
    setTimeout(() => {
      startCamera();
    }, 1000);
    setUserData(getUserdata());

    return () => {
      if (stream) {
        stream.getTracks().forEach((x) => x.stop());
      }
      setPageShown(false);
    };
  }, []);
  useEffect(() => {
    if (!userData) return;

    loadTodayAttendance();
    return () => { };
  }, [userData]);

  useEffect(() => {
    if (!userData) return;
    console.log("Setting up user schedule");
    /** Workhour : 0 for sunday, 6 for saturday  */
    const workHours = userData?.division?.workHours;
    console.log(userData);
    const dayOfWeek = moment().get("day");

    const workHourToday = workHours.filter((x) => x.day_of_week == dayOfWeek);
    setcurrentScheduleWFH(workHourToday);
    if (!workHours) {
      setErrors(["Work Hour for current user not defined"]);
      return;
    }

    return () => { };
  }, [userData]);

  useEffect(() => {
    const itv = setInterval(() => {
      if (hasFace) {
        countHasFace++
      } else {
        countHasFace = 0
        setAllowCapture(false)
      }
      if (countHasFace > 3) {
        setAllowCapture(true)
      }
    }, 1000);
    Axios.get(process.env.REACT_APP_SERVER_URL + "/schedule/office-areas")
      .then((resp) => {
        setofficeLocations(resp.data);
      })
      .catch((e) => { })
      .finally((e) => { });
    return () => {
      clearInterval(itv)
    };
  }, []);

  useEffect(() => {
    let watchId = -1;
    if (navigator.geolocation) {
      console.log("Checking device location...");
      setCheckingLocation(true);
      watchId = navigator.geolocation.watchPosition(
        (locationSuccess) => {
          setDetectedLocation(locationSuccess);
          console.log(locationSuccess);
        },
        (positionError) => {
          console.log(positionError);
          setDetectedLocation(null);
          setCheckingLocation(false);
        },
        {
          enableHighAccuracy: true,
          maximumAge: 30000,
        }
      );
    }
    return () => {
      navigator.geolocation.clearWatch(watchId);
    };
  }, [officeLocations]);
  useEffect(() => {
    if (!detectedLocation) return;
    const offices = [];
    for (let location of officeLocations) {
      location.geofences.sort((a, b) => a.leg - b.leg);
      const isLocationInsideOffice = contains(
        location.geofences.map((fence) => ({
          y: Number(fence.latitude),
          x: Number(fence.longitude),
        })),
        detectedLocation.coords.latitude,
        detectedLocation.coords.longitude
      );
      console.log(
        `Is location inside office `,
        location.office_area_name,
        ":",
        isLocationInsideOffice
      );
      if (isLocationInsideOffice) {
        offices.push(location);
      }
    }
    setTimeout(() => {
      setCheckingLocation(false);
    }, 1000);
    setDetectedOffices(offices);
    return () => { };
  }, [detectedLocation, officeLocations]);
  /** Ref */
  const camera = useRef(null);
  const videoContainer = useRef(null);

  const handleStream = (stream) => { };

  const startCamera = async () => {
    try {
      setStartingCamera(true);
      //   const devices = await navigator.mediaDevices.enumerateDevices();
      //   const videoDevices = devices.filter((d) => d.kind == "videoinput");
      console.log(videoContainer.current.clientHeight);
      if (videoContainer.current) {
        if (
          videoContainer.current.clientHeight <
          selfieCameraConstraint.video.height.max
        ) {
          selfieCameraConstraint.video.height.max =
            videoContainer.current.clientHeight;
        }
        if (
          videoContainer.current.clientWidth <
          selfieCameraConstraint.video.width.max
        ) {
          selfieCameraConstraint.video.width.max =
            videoContainer.current.clientWidth;
        }
      }
      stream = await navigator.mediaDevices.getUserMedia(
        selfieCameraConstraint
      );
      console.log(`Stream: `, stream);
      camera.current.srcObject = stream;
      camera.current.play();
    } catch (error) {
      console.log(error);
      setInfoText(
        <div>
          <p>
            <FontAwesomeIcon icon={faInfo} color="blue" /> Please Allow Us To
            Access Your Camera
            <br />
            {error.toString()}
          </p>
        </div>
      );
      setNotificationModalVisible(true);
    } finally {
      setStartingCamera(false);
    }
  };
  const capture = async () => {
    const canvas = document.createElement("canvas");
    canvas.width = camera.current.videoWidth;
    canvas.height = camera.current.videoHeight;

    console.log(canvas);
    const context = canvas.getContext("2d");
    context.drawImage(camera.current, 0, 0, canvas.width, canvas.height);
    const u = canvas.toDataURL("image/webp");
    setCapturedImage(u);
    setVerificationModalVisible(true);
  };
  const loadTodayAttendance = () => {
    /** Get today's attendance */
    setLoadingTodayAttendance(true);
    Axios.get(
      process.env.REACT_APP_SERVER_URL + "/attendance/today/" + userData.initial
    )
      .then((resp) => {
        setTodayAttendance(resp.data);
        setLoadingTodayAttendance(true);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally((e) => {
        setTimeout(() => {
          setLoadingTodayAttendance(false);
        }, 1000);
      });
  };
  const uploadAttendance = async () => {
    if (!userData) {
      console.log(`Userdata null`);
      return;
    }
    let selectedSchedule = null,
      early_secs = 0,
      late_secs = 0,
      workhour_start = null,
      workhour_end = null,
      exit_early_secs = 0,
      overtime_secs = 0;
    setUploadingAttendance(true);

    try {
      if (todayAttendance) {
        /** Exit Attendance */
        if (currentScheduleWFH.length > 0) {
          selectedSchedule = currentScheduleWFH[0];
        }
        if (selectedSchedule) {
          const now = moment();
          const scheduledExitDate = createDateFromSchedule(
            now.clone(),
            selectedSchedule.exit_at
          );
          const diffInSecs = scheduledExitDate.diff(now, "second");
          if (diffInSecs >= 0) exit_early_secs = diffInSecs;
          if (diffInSecs < 0) overtime_secs = Math.abs(diffInSecs);

          workhour_end = selectedSchedule.exit_at;
        }
        const resp = await Axios.put(
          process.env.REACT_APP_SERVER_URL +
          "/attendance/attend/wfh/" +
          todayAttendance.id,
          {
            exit_at: moment().format("YYYY-MM-DD HH:mm:ss"),
            imageData: capturedImage,
            user_code: userData.initial,
            attendance_status: "Exited",
            workhour_end,
            exit_early_secs,
            overtime_secs,
          }
        );
        if (resp.status == 200) {
          loadTodayAttendance();
          setVerificationModalVisible(false);
          setUploadSuccess(true);
        }
      } else {
        /** Entry Attendance */
        if (currentScheduleWFH.length > 0) {
          selectedSchedule = currentScheduleWFH[0];
        }
        /** User has schedule */
        if (selectedSchedule) {
          const now = moment();
          const scheduledEntryDate = createDateFromSchedule(
            now.clone(),
            selectedSchedule.entry_at
          );
          const diffInSecs = scheduledEntryDate.diff(now, "second");
          if (diffInSecs >= 0) early_secs = diffInSecs;
          if (diffInSecs < 0) late_secs = Math.abs(diffInSecs);

          workhour_start = selectedSchedule.entry_at;
        }
        const resp = await Axios.post(
          process.env.REACT_APP_SERVER_URL + "/attendance/attend/wfh",
          {
            user_code: userData.initial,
            fullname: userData.fullname,
            attendance_date: moment().format("YYYY-MM-DD HH:mm:ss"),
            entry_at: moment().format("YYYY-MM-DD HH:mm:ss"),
            exit_at: null,
            early_secs: early_secs,
            late_secs: late_secs,
            workhour_start: workhour_start,
            workhour_end: workhour_end,
            worktime_total_secs: -1,
            attendance_status: "Attended",
            attendance_type: detectedOffices.length > 0 ? "wfo" : "wfh",
            imageData: capturedImage,
            wfo_latitude: detectedLocation?.coords?.latitude ?? null,
            wfo_longitude: detectedLocation?.coords?.longitude ?? null,
            wfo_detected_office_location:
              detectedOffices.length > 0 ? detectedOffices[0].id : null,
          }
        );
        if (resp.status == 200) {
          loadTodayAttendance();
          setVerificationModalVisible(false);
          setUploadSuccess(true);
        }
      }
    } catch (error) {
      setInfoText(error.message);
      setNotificationModalVisible(true);
    } finally {
      setUploadingAttendance(false);
    }
  };
  const getImgUrl = (attdisplay) => {
    if (!todayAttendance) return null;
    if (attdisplay == "entry")
      return (
        process.env.REACT_APP_SERVER_URL +
        "/attendance/photo/" +
        todayAttendance.wfh_selfie_photo
      );
    if (attdisplay == "exit")
      return (
        process.env.REACT_APP_SERVER_URL +
        "/attendance/photo/" +
        todayAttendance.wfh_selfie_exit
      );
  };
  return (
    <CSSTransition classNames="alert" timeout={500} in={pageShown}>
      <div
        style={{ flex: 1, display: "flex", flexDirection: "column" }}
        className="wfh-capture bg-white "
      >
        <WingBlank size="md"></WingBlank>
        <Card class="today-attendance ">
          <Card.Header
            title={
              <div
                className="flex"
                style={{ textAlign: "center", flex: 1, color: "white" }}
              >
                <Link to={"/my_attendance"}>
                  <div className="logo drop-shadow-md">
                    <img src={logo} alt="logo" style={{ maxWidth: 120 }} />
                  </div>
                </Link>
              </div>
            }
          />
          <Card.Body className="today-attendance-info">
            {loadingTodayAttendance && (
              <React.Fragment>
                <p>
                  <FontAwesomeIcon spin icon={faSpinner} /> Loading...
                </p>
              </React.Fragment>
            )}
            {!loadingTodayAttendance && !todayAttendance && (
              <React.Fragment>
                <p className="no-record">
                  <FontAwesomeIcon icon={faExclamationTriangle} /> Kamu belum absen :)
                </p>
                <p className="attendance-hint">
                  Buruan dah absen biar ga diomelin
                </p>
              </React.Fragment>
            )}
            {!loadingTodayAttendance &&
              todayAttendance &&
              todayAttendance.exit_at == null && (
                <React.Fragment>
                  <p
                    className="entry-info"
                    onClick={(e) => {
                      setDisplayOnly(true);
                      setDisplayAttendance("entry");
                      setVerificationModalVisible(true);
                    }}
                  >
                    <span className="entry-title">
                      <FontAwesomeIcon icon={faCheckCircle} /> Kamu sudah absen di jam {" "}
                    </span>
                    <span className="entry-time">
                      {todayAttendance?.entry_at?.substring(0, 5)}
                    </span>
                  </p>
                </React.Fragment>
              )}

            {startingCamera && (
              <React.Fragment>
                <p>
                  <FontAwesomeIcon spin icon={faSpinner} /> Starting Camera...
                </p>
              </React.Fragment>
            )}
            {checkingLocation && (
              <React.Fragment>
                <p>
                  <FontAwesomeIcon spin icon={faSpinner} /> Checking Location...
                </p>
              </React.Fragment>
            )}
            {!checkingLocation && detectedOffices.length > 0 && (
              <React.Fragment>
                <p>
                  <FontAwesomeIcon icon={faLocationArrow} />{" "}
                  {detectedOffices[0].office_area_name}
                </p>
              </React.Fragment>
            )}
          </Card.Body>
          {!loadingTodayAttendance &&
            todayAttendance &&
            todayAttendance.exit_at != null && (
              <React.Fragment>
                <p className="entry-info">
                  <span className="entry-title">
                    <FontAwesomeIcon icon={faInfoCircle} /> Terimakasih!
                    <hr />
                    <small>
                      Kamu sudah berhasil absen untuk hari ini. Cuma 1x kok. Udah sana kerja jangan ngaca mulu
                    </small>
                  </span>
                  <span className="attendances-time">
                    <span
                      className="entry"
                      onClick={(e) => {
                        setDisplayOnly(true);
                        setDisplayAttendance("entry");
                        setVerificationModalVisible(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faSignInAlt} color="white" />{" "}
                      {todayAttendance?.entry_at?.substring(0, 5)}
                    </span>
                    <span
                      className="exit"
                      onClick={(e) => {
                        setDisplayOnly(true);
                        setDisplayAttendance("exit");
                        setVerificationModalVisible(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faSignOutAlt} color="white" />{" "}
                      {todayAttendance?.exit_at?.substring(0, 5)}
                    </span>
                  </span>
                </p>
              </React.Fragment>
            )}
        </Card>
        <div
          className="video-container md:flex-row flex-col gap-8"
          ref={videoContainer}
        >
          <video autoplay ref={camera} style={{ display: "none" }}></video>

          <Webcam
            ref={webcamRef}
            className="webcam"
            screenshotFormat="image/jpeg"
            style={{ visibility: "hidden", position: "absolute" }}
          />

          <img
            className="inputImage"
            alt="input"
            ref={imgRef}
            style={{ display: "none" }}
          />
          <canvas
            className="outputImage rounded"
            ref={faceImgRef}
            style={{ maxWidth: "500px" }}
          />
          {(allowCapture &&
            !loadingTodayAttendance &&
            (!todayAttendance || !todayAttendance?.exit_at)) ? (
            <div className="capture-button" onClick={(e) => capture()}>
              <FontAwesomeIcon icon={faCamera} />
            </div>
          ) : null}
          {!modelLoaded && startingCamera && (
            <div>
              <FontAwesomeIcon spin icon={faSpinner} /> Loading camera...
            </div>
          )}

          <div className="text-center">
            {faceData ? (
              <>
                <h2 className="text-2xl	font-medium">
                  Kamera Sudah Diaktifkan!
                </h2>
                <p className="text-base">
                  Silahkan ambil foto untuk absen ! Pastikan Stand Still
                  diCamera selama 3 Detik!
                </p>
              </>
            ) : (
              <>
                <h2 className="text-2xl	font-medium">
                  Aktifkan Kamera & Pasang Wajahmu
                </h2>
                <p className="text-base">Untuk dapat melakukan absen</p>
              </>
            )}
          </div>
        </div>
        <Modal
          style={{ width: "75%" }}
          visible={notificationModalVisible}
          transparent
          maskClosable={false}
          onClose={(e) => setNotificationModalVisible(false)}
          title={"Information"}
          footer={[
            {
              text: "Cancel",

              onPress: (e) => {
                setNotificationModalVisible(false);
                props.history.goBack();
              },
            },
            {
              text: "Retry",
              onPress: (e) => {
                startCamera();
                setNotificationModalVisible(false);
              },
            },
          ]}
        >
          {infoText}
        </Modal>
        <Modal
          className="confirmation-modal"
          style={{ width: "250px", marginLeft: 200 }}
          visible={verificationModalVisible}
          transparent
          maskClosable={false}
          onClose={(e) => setVerificationModalVisible(false)}
          title={"Information"}
          footer={
            displayOnly
              ? [
                {
                  text: "Close",
                  onPress: (e) => {
                    if (!uploadingAttendance) {
                      startCamera();
                      setVerificationModalVisible(false);
                      setTimeout(() => {
                        setDisplayOnly(false);
                      }, 500);
                    }
                  },
                },
              ]
              : [
                {
                  text: "Retake",
                  onPress: (e) => {
                    if (!uploadingAttendance) {
                      startCamera();
                      setVerificationModalVisible(false);
                    }
                  },
                },
                {
                  text: uploadingAttendance ? "Sending..." : "Send",
                  onPress: (e) => {
                    if (!uploadingAttendance) uploadAttendance();
                  },
                },
              ]
          }
        >
          <div
            className="mr-0"
            style={{
              width: "100%",
              height: "75%",
            }}
          >
            <img
              src={displayOnly ? getImgUrl(displayAttendance) : capturedImage}
              style={{
                width: "100%",
                height: "75%",
              }}
            />

            {!displayOnly && !uploadingAttendance && (
              <p className="mt-2" style={{ fontSize: 12 }}>
                You can click <strong>Send</strong> to send this picture as your
                attendance, or <strong>Retake</strong> if you need to take
                another shot
              </p>
            )}
            {!displayOnly && uploadingAttendance && (
              <React.Fragment>
                <p>
                  <FontAwesomeIcon spin icon={faSpinner} /> Uploading...
                </p>
              </React.Fragment>
            )}
            {displayOnly && (
              <React.Fragment>
                <p>
                  This was your{" "}
                  {displayAttendance == "entry" ? "entry" : "exit"} attendance
                  at{" "}
                  {displayAttendance == "entry"
                    ? todayAttendance?.entry_at?.substring(0, 5)
                    : todayAttendance?.exit_at?.substring(0, 5)}
                </p>
              </React.Fragment>
            )}
          </div>
        </Modal>
        <Modal
          visible={uploadSuccess}
          transparent
          maskClosable={false}
          onClose={(e) => setUploadSuccess(false)}
          title={"Attendance Information"}
          footer={[
            {
              text: "Close",
              onPress: (e) => {
                setUploadSuccess(false);
              },
            },
          ]}
        >
          <div
            style={{
              width: "100%",
              height: "auto",
            }}
          >
            <Result
              img={
                <img
                  style={{ width: "100%" }}
                  src={require("../../../assets/attendance-complete.png")}
                />
              }
              title="Entry Attendance Recorded"
              message={
                <div>
                  Thank you. Your attendance has been recorded.
                  <br />
                </div>
              }
            />
          </div>
        </Modal>
      </div>
    </CSSTransition>
  );
}
