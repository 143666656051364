import React, { useState, useEffect } from "react";
import Axios from "axios";
import NumberFormat from "react-number-format";
import {
  List,
  Picker,
  Flex,
  Button,
  NavBar,
  Icon,
  WhiteSpace,
  TextareaItem,
  InputItem,
  Result,
  Modal,
} from "antd-mobile";
import { Upload } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble, faImage } from "@fortawesome/free-solid-svg-icons";
import { CSSTransition } from "react-transition-group";
import Sidebar from "../../Components/Sidebar";

const ReimbursementRequest = (props) => {
  let { open, toggle } = props;

  let userLogin = JSON.parse(localStorage.getItem("_dentonslogin"));

  //   const { loading, error, data, refetch } = useQuery(QUERY, {
  //     fetchPolicy: "cache-and-network",
  //   });

  //   const [mutate] = useMutation(MUTATE);
  const [openMatter, setOpenMatter] = useState(false);
  const [reimbursementType, setReimbursementstate] = useState({});

  let initialData = {
    request_type: "",
    description: "",
    amount_requested: 0,
    client_code: "-",
    approval_assignment: [
      {
        user_code: "DSB",
      },
    ],
  };

  const [requestData, setRequestData] = useState(initialData);
  const [, forceUpdate] = useState();
  const [fileData, setFileData] = useState([
    {
      file_name: null,
      file: null,
    },
  ]);

  const [uploadData, setUploadData] = useState(false);
  const [openClientID, setOpenClientID] = useState(false);
  const [clientId, setClientId] = useState(null);
  const [fileError, setFileError] = useState([]);
  const [openError, setOpenError] = useState(false);
  const [fileDataCheck, setFileDataCheck] = useState(true);
  const [pageShown, setPageShown] = useState(false);
  const [saving, setSaving] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [approvalCheck, setApprovalCheck] = useState(true);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [selectedReimbursementType, setSelectedReimbursementType] =
    useState(null);
  const [reimbursementTypes, setReimbursementTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [approvalBy, setApprovalBy] = useState([
    {
      user_code: "DSB",
    },
    // {
    //   user_code: null,
    // },
  ]);
  const loadReimbursementTypes = () => {
    setLoading(true);
    Axios.get(
      process.env.REACT_APP_SERVER_URL + "/reimbursement/reimbursement-types"
    )
      .then((resp) => {
        if (resp.status == 200) {
          setReimbursementTypes(resp.data);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally((e) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    setRequestData({
      ...requestData,
      request_type: selectedReimbursementType
        ? selectedReimbursementType[0]
        : 0,
    });
    return () => {};
  }, [selectedReimbursementType]);
  useEffect(() => {
    loadReimbursementTypes();
    setPageShown(true);

    return () => {
      setPageShown(false);
    };
  }, []);
  useEffect(() => {
    setFileData([
      {
        file_name: null,
        file: null,
      },
    ]);
  }, [toggle]);

  const upload = async (id) => {
    if (fileData.length > 0) {
      const formData = new FormData();

      //multi
      fileData.forEach((i, x) => {
        formData.append("file", i.file);
        formData.append("name[]", i.file_name);
      });
      try {
        forceUpdate({});
        const resp = Axios.post(
          process.env.REACT_APP_DASHBOARD_URL + "/employee_reimbursement/" + id,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setUploadSuccess(true);
        toggle();
        setUploadData(false);
      } catch (error) {
        setFileError(["Upload Error"]);
        setOpenError(true);
      } finally {
        forceUpdate({});
      }
    }
  };

  function alertNotify(event) {}

  function handleSave() {
    // console.log(requestData);
    // return;
    //checking file empty
    setUploadData(true);
    let save = false;
    let error = false;

    if (fileData.length >= 1) {
      let nullFile = fileData.filter(
        (x) => x.file_name === null || x.file === null || x.file_name === ""
      );
      if (nullFile.length > 0) {
        if (
          nullFile[0].file_name === null ||
          nullFile[0].file === null ||
          nullFile[0].file_name === ""
        ) {
          error = true;
        }
      } else save = true;
    } else error = true;

    // save or Error
    console.log(save);
    if (save) {
      saveReimbursement();
    } else {
      setFileError(["File or File Name should not be empty!"]);
      setOpenError(true);
    }
  }

  async function saveReimbursement() {
    try {
      console.log(process.env.REACT_APP_SERVER_URL + "/reimbursement/save");
      const resp = await Axios.post(
        process.env.REACT_APP_SERVER_URL + "/reimbursement/save",
        requestData
      );
      if (resp.status == 200) {
        let id = resp.data.id;
        upload(id);
      }
      //   const res = await mutate({
      //     variables: {
      //       data: {
      //         ...requestData,
      //       },
      //     },
      //   });
      //   let id = res.data.MutateEmployeeReimbursement.id;
      //   upload(id);
    } catch (error) {
      setFileError([error.message]);
      setOpenError(true);
    }
  }

  useEffect(() => {
    let approvalByNull =
      approvalBy.find(({ user_code }) => user_code === null) || false;

    let fileDataNull =
      fileData.find(
        ({ file_name, file }) => file_name === null || file === null
      ) || false;

    setFileDataCheck(fileDataNull);
    setApprovalCheck(approvalByNull);
  }, [fileData, approvalBy]);

  const handleReimbursementType = (e) => {
    setSelectedReimbursementType([`${e.target.value}`]);
  };

  return (
    <div className="h-full">
      <div className="flex h-full">
        <Sidebar />
        <div className="flex-1 p-7 float-right">
          <div>
            <Flex>
              <Flex.Item>
                <div className=" text-dark-black font-bold text-lg mt-4 mb-5">
                  Request Reimbursement
                </div>
                <label
                  for="reimbursement"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Reimbursement Category
                </label>
                <select
                  id="reimbursement"
                  className=" border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 max-w-xs"
                  onChange={(e) => handleReimbursementType(e)}
                  value={selectedReimbursementType}
                >
                  {reimbursementTypes.map((x) => (
                    <option value={x.reimbursement_type}>
                      {x.reimbursement_type}
                    </option>
                  ))}
                </select>

                <div className="mt-4">
                  <label
                    for="visitors"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Amount
                  </label>
                  <NumberFormat
                    class=" border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 max-w-xs"
                    thousandSeparator={true}
                    prefix={"Rp. "}
                    id="nominal"
                    name="nominal"
                    style={{ textAlign: "left" }}
                    placeholder="Rp. 250.000"
                    onChange={(e) => {
                      let initData = e.target.value.replace(/[^0-9]/g, "");
                      setRequestData({
                        ...requestData,
                        amount_requested: parseFloat(initData),
                      });
                    }}
                  />
                </div>
                <label
                  for="upload"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white mt-4"
                >
                  Upload Proof of Receipt
                </label>
                <Upload
                  multiple
                  listType="text"
                  itemRender={(e, file, fileList) => {
                    return (
                      <span
                        style={{
                          fontSize: 14,
                          display: "block",
                          maxHeight: 15,
                          verticalAlign: "middle",
                          marginTop: 8,
                        }}
                      >
                        * <FontAwesomeIcon icon={faImage} /> &nbsp;
                        {file.name}
                      </span>
                    );
                  }}
                  beforeUpload={(file) => {
                    console.log(`FILE: `, file);
                    const row = [...fileData];
                    row[0] = {
                      ...row[0],
                      file: file ?? null,
                      file_name: file.name,
                    };
                    setFileData(row);
                    return false;
                  }}
                >
                  <Button className="bg-transparent border-none h-full">
                    <div class="flex  w-full">
                      <label
                        for="dropzone-file"
                        class="flex flex-col items-center justify-center w-96 h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                      >
                        <div class="flex flex-col items-center justify-center pt-5 pb-6">
                          <svg
                            aria-hidden="true"
                            class="w-10 h-10 mb-3 text-red-600"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                            ></path>
                          </svg>
                          <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                            <span class="font-semibold">Click to upload</span>{" "}
                            or drag and drop
                          </p>
                          <p class="text-xs text-gray-500 dark:text-gray-400">
                            SVG, PNG, JPG or GIF (MAX. 800x400px)
                          </p>
                        </div>
                      </label>
                    </div>
                  </Button>
                </Upload>

                <div className="mt-4 mb-4">
                  <label
                    for="message"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Notes
                  </label>
                  <textarea
                    value={requestData.description}
                    onChange={(e) =>
                      setRequestData({
                        ...requestData,
                        description: e.target.value,
                      })
                    }
                    id="message"
                    rows="4"
                    className=" border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 max-w-xs"
                    required
                  ></textarea>
                </div>

                <Button
                  className="inline-block px-6 py-3  text-white font-medium text-lg leading-tight rounded shadow-mdhover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out max-w-xs mb-3 mt-5"
                  color="success"
                  disabled={
                    requestData.request_type === "" ||
                    requestData.amount_requested === 0 ||
                    requestData.description === "" ||
                    approvalCheck ||
                    uploading ||
                    saving
                  }
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, #C83131, #EF4444  )",
                  }}
                  onClick={handleSave}
                >
                  {/* <i
                    className={`fa fa-${
                      uploading || saving ? "spin fa-spinner" : "save"
                    } mr-1`}
                  ></i> */}
                  Request Reimbursement
                </Button>
                <Modal
                  visible={uploadSuccess}
                  transparent
                  maskClosable={false}
                  onClose={(e) => {
                    setUploadSuccess(false);
                    window.history.back();
                  }}
                  title={"Reimbursement Information"}
                  footer={[
                    {
                      text: "Close",
                      onPress: (e) => {
                        setUploadSuccess(false);
                        window.history.back();
                      },
                    },
                  ]}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "auto",
                    }}
                  >
                    <Result
                      img={
                        <img
                          style={{ width: "100%" }}
                          src={require("../../assets/attendance-complete.png")}
                        />
                      }
                      title="Reimbursement Recorded"
                      message={
                        <div>
                          Thank you. Your reimbursement has been recorded.
                          <br />
                        </div>
                      }
                    />
                  </div>
                </Modal>
              </Flex.Item>
            </Flex>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReimbursementRequest;
