import React, { Component, useState, useRef, useEffect } from "react";
import { NavBar, Icon, WingBlank, DatePicker, WhiteSpace, List, InputItem, Result, Modal } from "antd-mobile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCheck,
	faHome,
	faSpinner,
	faExclamationCircle,
	faExclamationTriangle,
	faInfo,
	faInfoCircle,
	faEnvelope,
	faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import "./index.scss";
import Axios from "axios";
import moment from "moment";
import { CSSTransition } from "react-transition-group";
import Sidebar from "../../Components/Sidebar";
import payrollIcon from "../../assets/icons/payroll.svg";

export default function MyAttendance(props) {
	/** State */
	const [dateFrom, setDateFrom] = useState(moment().add(-1, "month").toDate());
	const [dateTo, setDateTo] = useState(new Date());
	const [scrollHeight, setScrollHeight] = useState(500);
	const [loadingPayroll, setLoadingPayroll] = useState(false);
	const [payrollData, setPayrollData] = useState([]);
	const [errors, setErrors] = useState([]);
	const [loadingError, setLoadingError] = useState(false);
	const [pageShown, setPageShown] = useState(false);
	const [confirmSendEmail, setConfirmSendEmail] = useState(false);
	const [confirmSendPayrollData, setConfirmSendPayrollData] = useState(null);
	const [sendingPayroll, setSendingPayroll] = useState(false);
	const [payrollSent, setPayrollSent] = useState(false);
	/** Refs */
	const payrollSectionContainer = useRef(null);
	const payrollSection = useRef(null);

	/** When page loads and container has heights */
	useEffect(() => {
		/** Transition */
		setPageShown(true);
		return () => {
			setPageShown(false);
		};
	}, []);
	useEffect(() => {
		setScrollHeight(payrollSectionContainer.current?.clientHeight ?? 501);
		return () => {};
	}, [payrollSectionContainer.current]);
	useEffect(() => {
		loadData();
		return () => {};
	}, [dateTo, dateFrom]);
	useEffect(() => {
		if (confirmSendEmail == false) {
			setPayrollSent(false);
			setSendingPayroll(false);
		}
		return () => {};
	}, [confirmSendEmail]);

	/** Functions */
	const mailPayroll = async () => {
		try {
			if (sendingPayroll) return;
			setSendingPayroll(true);
			const resp = await Axios.post(process.env.REACT_APP_SERVER_URL + `/payroll/${confirmSendPayrollData.id}/mail`);
			if (resp.status == 200) {
				setPayrollSent(true);
				setTimeout(() => {
					setConfirmSendEmail(false);
				}, 1000);
			}
		} catch (error) {
			alert(error);
		} finally {
			setSendingPayroll(false);
		}
	};
	const loadData = () => {
		setLoadingPayroll(true);
		Axios.get(process.env.REACT_APP_SERVER_URL + "/payroll/my-payroll", {
			params: {
				dateFrom: dateFrom,
				dateTo: dateTo,
			},
		})
			.then((resp) => {
				if (resp.status == 200) {
					setLoadingError(false);
					setErrors([]);
					setPayrollData(resp.data);
				}
			})
			.catch((e) => {
				setErrors([e]);
				setLoadingError(true);
			})
			.finally((e) => {
				setLoadingPayroll(false);
			});
	};

	return (
		<div>
			<div className="flex">
				<Sidebar />
				<div className="h-screen flex-1 p-7">
					<WingBlank size="md">
						<div
							className="alertMessage flex bg-white border-gray-300 border p-5 rounded drop-shadow-md"
							style={{ maxWidth: "380px", fontSize: "12px" }}
						>
							<div className="mr-2 bg-red-300 p-2 rounded" style={{ height: 48 }}>
								<img src={payrollIcon}></img>
							</div>
							<div className="mr-2">
								<h5 className="font-bold text-sm">Slip-Gaji-Guido.pdf</h5>
								<p>Here is Your Payroll Slip Document!</p>
							</div>
							<button className="border border-red-500 p-2 font-bold rounded drop-shadow-sm	text-red-600">Download</button>
						</div>

						<div className="sub-title">
							{loadingPayroll && (
								<React.Fragment>
									<FontAwesomeIcon spin icon={faSpinner} />{" "}
								</React.Fragment>
							)}
							Registered Payroll Slip
						</div>
						<p
							style={{
								fontSize: 12,
								fontWeight: 400,
								color: "#999",
								padding: "0px 0 9px 15px",
								marginTop: "0px",
							}}
						>
							Please take note that payroll slip is a very personal document. If you don't feel comfortable for your payroll to
							be uploaded please contact your HR
						</p>
						{loadingError && (
							<div className="sub-title errors align-center" onClick={(e) => loadData()}>
								<FontAwesomeIcon icon={faExclamationTriangle} /> Cannot load your attendance history. Tap to retry
							</div>
						)}
						{!loadingPayroll && !loadingError && payrollData.length < 1 && (
							<div className="sub-title align-center" onClick={(e) => loadData()}>
								<FontAwesomeIcon icon={faInfoCircle} /> No data found for selected date range.
							</div>
						)}
					</WingBlank>
					<div className="payroll-section-container" ref={payrollSectionContainer}>
						<div
							className="payroll-section"
							style={{
								height: scrollHeight,
							}}
							ref={payrollSection}
						>
							{(payrollData ?? []).map((x) => (
								<PayrollList
									onClick={(e) => {
										setConfirmSendEmail(true);
										setConfirmSendPayrollData(x);
									}}
									payroll={x}
								/>
							))}
						</div>
					</div>
					<Modal
						visible={confirmSendEmail}
						transparent
						maskClosable={false}
						onClose={(e) => setConfirmSendEmail(false)}
						title={"E-Mail Confirmation"}
						footer={[
							{
								text: "Close",
								onPress: (e) => {
									setConfirmSendEmail(false);
								},
							},
							{
								enabled: false,
								text: sendingPayroll ? "Sending..." : "Send",
								onPress: (e) => {
									mailPayroll();
								},
							},
						]}
					>
						<div
							style={{
								width: "100%",
								height: "auto",
							}}
						>
							<Result
								img={
									<FontAwesomeIcon
										spin={sendingPayroll}
										style={{
											fontSize: 45,
											color: payrollSent ? "#ff9800" : "green",
										}}
										icon={sendingPayroll ? faSpinner : payrollSent ? faCheckCircle : faEnvelope}
									/>
								}
								title={"E-Mail Payroll Slip"}
								message={
									payrollSent ? (
										<div>Your payroll has been sent!</div>
									) : (
										<div>Press confirm to send your payroll to your email</div>
									)
								}
							/>
						</div>
					</Modal>
				</div>
			</div>
		</div>
	);
}

function PayrollList(props) {
	const isHeader = props.header != null;
	const { payroll } = props;
	return (
		<div
			className={"payroll-list-item" + (isHeader ? " header" : "")}
			onClick={(e) => {
				e.preventDefault();
				if (props.onClick) props.onClick();
			}}
		>
			<div className="list-meta">
				<h4>{moment(payroll.payroll_periode, "YYYYMM").format("MMM YYYY")}</h4>
				<h5>Click to email your payroll</h5>
			</div>
			<div className="list-time">
				<span className={"enter-time ok"}>
					<FontAwesomeIcon icon={faEnvelope} />{" "}
				</span>
			</div>
		</div>
	);
}
