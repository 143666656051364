import React from "react";
import logo from "./logo.svg";
import "./App.scss";
import { Button, Icon, NavBar, LocaleProvider } from "antd-mobile";
import { HashRouter, Route, Switch } from "react-router-dom";
import HomePage from "./Pages/Home";
import CheckInWFH from "./Pages/Checkin/WFH";
import CheckInWFO from "./Pages/Checkin/WFO";
import MyAttendance from "./Pages/MyAttendance";
import enUS from "antd-mobile/lib/locale-provider/en_US";
import Login from "./Pages/Login";
import Axios from "axios";
import { getUserdata } from "./Libs/Userdata";
import LeaveRequest from "./Pages/Leave/LeaveRequest";
import LeaveHistory from "./Pages/Leave/LeaveHistory";
import WorkSchedule from "./Pages/WorkSchedule";
import ReimbursementRequest from "./Pages/ReimbursementRequest";
import PayrollSlip from "./Pages/PayrollSlip";
import reimbursementDashboard from "./Pages/ReimbursementRequest/reimbursementDashboard";
import ApprovalHistory from "./Pages/Approval";

Axios.interceptors.request.use((config) => {
	const userdata = getUserdata();
	console.log(config.url);
	if (userdata && config.url.indexOf("googleapis.com") == -1) {
		config.headers.Authorization = userdata.access_token;
	}
	return config;
});

function App() {
	return (
		<div className="main-window">
			<Switch>
				<Route path="/checkin_wfh" component={CheckInWFH} />
				<Route path="/checkin_wfo" component={CheckInWFO} />
				<Route path="/my_attendance" component={MyAttendance} />
				<Route path="/leave/request" component={LeaveRequest} />
				<Route path="/leave/history" component={LeaveHistory} />
				<Route path="/schedules/my-schedule" component={WorkSchedule} />
				<Route path="/login" component={Login} />
				<Route path="/reimbursement" component={reimbursementDashboard} />
				<Route path="/reimbursement-request" component={ReimbursementRequest} />
				<Route path="/payroll" component={PayrollSlip} />
				<Route path="/approval-history" component={ApprovalHistory} />
				<Route path="*" component={MyAttendance} />
				{/* <Route path="/" component={HomePage} /> */}
			</Switch>
		</div>
	);
}

export default App;
