import moment from "moment";

export default function createDateFromSchedule(date, scheduleStr) {
  if (!date) {
    date = moment();
  }

  /** Schedule string should be like 08:00:00 */
  const scheduleSplit = scheduleStr.split(":");
  const hour = Number(scheduleSplit[0]);
  const minute = Number(scheduleSplit[1]);
  const second = Number(scheduleSplit[2]);

  date.set("hour", hour);
  date.set("minute", minute);
  date.set("second", second);

  console.log("set date ", date.format("YYYY-MM-DD HH:mm:ss"));

  return date;
}
